.base {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.base img {
    max-width: 250px;
}

.base .links {
    display: flex;
    align-items: center;
    color: #1DB0D7;
    font-weight: bold;
}

.base .links span.NavLink {
    text-decoration: none;
    cursor: pointer;
    padding: 20px;
    transition: 100ms;
}

.base .links span.NavLink:hover {
    border-bottom: 2px solid #1DB0D7;
}

@media (max-width: 654px) {
    .base .links > span {
        display: none;
    }
}


@media (max-width: 454px) {
    .base .links {
        display: none;
    }
}